import Vue from "vue"
import { differenceInMinutes } from "date-fns"

export default async function fetch({ isDev, route, store, env, params, query, req, res, redirect, error }) {
  // console.log('store appState ===> ', store.state.appState.has_data_loaded)
  // console.log('store user ===> ', store.state.user)
  // console.log('route: ', route)
  // console.log('params: ', params)
  if (store.state.appState.has_data_loaded === false) {
    try {
      const { account_id } = params
      // console.log('account_id ===> ', account_id, store.state.user)


      if (account_id) {
        return Vue.firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
          const user = store.getters["user/getUser"]
          if ((user.is_super_admin || user.is_website_builder) || user.accountIndex[account_id]) {
            store.dispatch("user/setCurrentAuthToken", idToken)
            axios.defaults.headers.common['Authorization'] = idToken
            // Update selected account
            let data = await Promise.all([store.dispatch("accounts/setAccounts", {
              account_id: account_id,
              relations: "address,review_settings,websites,main_image,demo_unit_variables,settings,global_settings,global_settings.schema",
            }), store.dispatch("user/setSelectedAccountId", account_id), store.dispatch("accounts/setSelectedAccount", account_id, { root: true })])

            if (window && window.Intercom) {
              window.Intercom('update', { "last_selected_account_id": account_id })
            }
            // Update store with selected account
            const res = data[0]

            const accountId = store.getters["user/getSelectedAccountId"]
            // const user = store.getters["user/getUser"]
            if (accountId && user) {
              store.dispatch("navigation/buildSidebarMenu", {})
            }

            var locationsWithAddressTimeSet = store.getters["locations/getLocationsWithAddressTimeSet"]
            if (locationsWithAddressTimeSet == null) {
              store.dispatch("locations/setLocationsWithAddress")
            } else {
              let diffInMins = differenceInMinutes(new Date(), new Date(locationsWithAddressTimeSet))
              if (diffInMins >= 30) {
                store.dispatch("locations/setLocationsWithAddress")
              }
            }

            // let filter = await Vue.$vlf.getItem(`filterLocations_${this.selectedAccount.id}`)
            // if (filter) {
            //   // store.dispatch("unitManager/setUnitLocationFilters", filter)
            //   // store.commit("leads/updateFilterLocations", filter)
            // }
            // Update data has loaded flag
            store.dispatch("appState/setDataHasLoaded", true)
            // console.log('data loaded')
            return res
          }
          else {
            redirect('/accounts')
          }
        }).catch(function (error) {
          // Handle error
          return error
        })
      }


      return
    } catch (e) {
      Vue.rollbar.error('Error loading data', e, { params: params })
      return e
    }
  } else {
    return true
  }
}
