export default {
  mounted() {
    this.$nextTick(() => { })
  },
  methods: {
    sendHotjarEvent(eventData) {
      try {
        if (process.client && this.$config.stage !== "dev" && this.$config.stage !== "development" && window) {
          let user = this.$store.getters["user/getUser"]

          if (user && user.id) {
            window.smartlook("identify", user.id, {
              name: `${ user.name ? user.name : '' }`,
              email: `${ user.email }`
            })
          }
          if (window && window.smartlook) {
            window.smartlook("track", eventData.action, eventData)
          }
          if (window && window.Intercom) {
            window.Intercom('trackEvent', eventData.action, eventData)
          }
        }
      } catch (e) {
        console.error("Hotjar event error", e)
        this.$rollbar.error("Hotjar event error", e)
      }
    },
    sendHotjarTag(tags) {
      try {
        if (process.client && this.$config.stage !== "dev" && this.$config.stage !== "development" && window && window.smartlook) {
          // let account = this.$store.getters["clientWebsite/account"]
          // let loc = this.$store.getters["locations/getSelectedLocation"]
          // let user = this.$store.getters["user/getUser"]
          // if (user && user.id) {
          //   window.smartlook("identify", user.id, {
          //     name: `${ user.name ? user.name : '' }`,
          //     email: `${ user.email }`
          //   })
          // }


          // if (account && account.name) tags.push(account.name)
          // if (loc && loc.name) {
          //   tags.push(loc.name)
          // }
          // for (let tag of tags) {
          //   if (window && window.smartlook) {
          //     window.smartlook("track", tag)
          //   }
          //   if (window && window.Intercom) {
          //     window.Intercom('trackEvent', tag)
          //   }
          // }
        }
      } catch (e) {
        console.error("Hotjar tag error", e)
        this.$rollbar.error("Hotjar tag error", e)
      }
    },
  },
}
