import BaseAlert from '@/helpers/BaseAlert'
import Vue from 'vue'

export default {
  methods: {
    toastError(msg = `Error!`, noAutoHide = false) {
      var ComponentClass = Vue.extend(BaseAlert)
      var instance = new ComponentClass({
        propsData: { text: msg, type: 'danger', title: 'Error!', icon: 'alert-circle', autoHideDelay: noAutoHide ? undefined : 4000 }
      })
      const div = document.createElement('div')
      $('#notification__list').append(div)
      instance.$mount(div)
    },
    toastWarning(msg = `Error!`, noAutoHide = false) {
      var ComponentClass = Vue.extend(BaseAlert)
      var instance = new ComponentClass({
        propsData: { text: msg, type: 'warning', title: 'Warning!', icon: 'alert-circle', autoHideDelay: noAutoHide ? undefined : 4000 }
      })
      const div = document.createElement('div')
      $('#notification__list').append(div)
      instance.$mount(div)
    },
    toastErrorAlt(msg = `Error!`, noAutoHide = false) {
      // this.$bvToast.toast(msg, {
      //   title: 'Error',
      //   autoHideDelay: 5000,
      //   noAutoHide,
      //   variant: 'danger',
      //   toaster: toaster
      // })
      var ComponentClass = Vue.extend(BaseAlert)
      var instance = new ComponentClass({
        propsData: { text: msg, type: 'danger', title: 'Error!', icon: 'alert-circle', autoHideDelay: noAutoHide ? undefined : 8000 }
      })
      const div = document.createElement('div')
      $('#notification__list').append(div)
      instance.$mount(div)
    },
    toastSuccess(msg = `Success!`, noAutoHide = false) {
      var ComponentClass = Vue.extend(BaseAlert)
      var instance = new ComponentClass({
        // propsData: { text: msg, type: 'success', title: 'Success!', icon: 'check-circle' }
        propsData: { text: msg, type: 'success', title: 'Success!', icon: 'check-circle', autoHideDelay: noAutoHide ? undefined : 4000 }
      })
      const div = document.createElement('div')
      $('#notification__list').append(div)
      instance.$mount(div)
      // this.$bvToast.toast(msg, {
      //   title: 'Success',
      //   autoHideDelay: 4000,
      //   variant: 'success',
      //   toaster: toaster
      // })
    },
    toastInfo(msg = `Success!`, noAutoHide = false) {
      var ComponentClass = Vue.extend(BaseAlert)
      var instance = new ComponentClass({
        propsData: { text: msg, type: 'info', title: 'Info!', icon: 'info', autoHideDelay: noAutoHide ? undefined : 4000 }
      })
      const div = document.createElement('div')
      $('#notification__list').append(div)
      instance.$mount(div)
      // this.$bvToast.toast(msg, {
      //   title: 'Info',
      //   autoHideDelay: 4000,
      //   variant: 'info',
      //   toaster: 'b-toaster-bottom-center'
      // })
    }
  }
}
