import { mapGetters, mapMutations } from "vuex"

export default {
  computed: {
    ...mapGetters({
      isDarkMode: "layoutVars/getIsDarkMode"
    })
  },
  created() {
    let cook = this.getCookie("pug_dark_mode")
    setTimeout(() => {
      if (cook !== undefined) {
        this.setDarkMode(cook)
        // console.log("created -> cook", cook, Boolean(cook))
        if (cook == "t") {
          document.getElementById("__nuxt").classList.add("pug-dark-mode")
          document.getElementById("__nuxt").classList.remove("bg-light")
        } else {
          document.getElementById("__nuxt").classList.add("bg-light")
        }
      } else {
        document.getElementById("__nuxt").classList.add("bg-light")
      }
    })
  },
  async mounted() {
    // this.listenForWebsocket()

  },
  watch: {
    isDarkMode() {
      if (this.isDarkMode == "t") {
        document.getElementById("__nuxt").classList.remove("bg-light")
        document.getElementById("__nuxt").classList.add("pug-dark-mode")
      } else if (this.isDarkMode == "f") {
        document.getElementById("__nuxt").classList.add("bg-light")
        document.getElementById("__nuxt").classList.remove("pug-dark-mode")
      }
    }
  },
  methods: {
    ...mapMutations({
      setDarkMode: "layoutVars/setDarkMode"
    }),
    getCookie(name) {
      var value = "; " + document.cookie
      var parts = value.split("; " + name + "=")
      if (parts.length == 2)
        return parts
          .pop()
          .split(";")
          .shift()
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      var expires = "expires=" + d.toUTCString()
      var url = new URL(window.origin)
      var cookieUrl = url.host
      if (url.hostname !== "localhost") cookieUrl = "." + url.host.match(/[^\.]*\.[^.]*$/)[0]
      else cookieUrl = url.hostname
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=None;"
      // console.log(document.cookie)
    }
  }
}
