import { ApiFactory } from "@/utils/apis/ApiFactory"
const UserApi = ApiFactory.get("user")
import Vue from "vue"
import _ from "lodash"
import { generateApiUrl } from "@/utils/helpers"
import { EventBus } from "@/utils/event-bus"

export default function (context) {
  //
  if (process.client) {
    // let currentUser = firebase.auth().currentUser
    // console.log("🚀 ~ file: auth.js ~ line 16 ~ currentUser", currentUser)
    // if (currentUser) {
    //   await signIn(context)
    // }
    return new Promise((resolve, reject) => {
      var unsubscribe = firebase.auth().onAuthStateChanged(async (data) => {
        unsubscribe()
        // console.log("🚀 ~ file: auth.js ~ line 20 ~ firebase.auth ~ data", data)
        if (data) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          var uid = data.uid
          await signIn(context, data)
          return resolve()
        } else {
          // User is signed out
          await context.store.dispatch("user/setLoggedIn", false)
          await context.store.dispatch("user/setAccountSwitcherAccounts", null)
          window.Intercom('shutdown')
          window.Intercom("boot", {
            app_id: context.$config.stage === "production" ? "b4bdwx43" : "diipufb9"
          })
          if (context.route.path !== "/login" && context.route.path !== "/login/forgot-password" && context.route.path !== "/chrome" && context.route.path !== "/track") {
            context.redirect("/login")
          }
          EventBus.$emit('user_logged_out')
          return resolve()
        }

      })
    })
  }
}

function signIn(context, fbUser) {
  return new Promise(async (resolve, reject) => {
    // var fbUser = firebase.auth().currentUser
    var user = context.store.getters["user/getUser"]
    var hasBeenInitialized = context.store.getters["user/getHasBeenInitialized"]

    setInterval(async () => {
      let idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
      axios.defaults.headers.common['Authorization'] = idToken
      context.store.dispatch("user/setCurrentAuthToken", idToken)
    }, 60 * 1000 * 15)

    if (!hasBeenInitialized && user === null) {

      let idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
      axios.defaults.headers.common['Authorization'] = idToken
      context.store.dispatch("user/setCurrentAuthToken", idToken)

      // Get and set cookies for snowplow
      var pug_id = getCookie("pugg_uid")
      // console.log("!hasBeenInitialized && user === null")
      try {
        let url = generateApiUrl(`user-dashboard/${ fbUser.uid }`)
        let { data: res } = await axios.get(url, { headers: { 'x-purge': true } })
        if (res.data) {
          initServices(res.data, context)
          if (res.data.has_logged_in === false) {
            res.data.has_logged_in = true
            await UserApi.update(res.data, null)
          }
          setCookie("pugg_dash", res.data.id, 4)

          context.store.dispatch("user/setHasBeenInitialized", true)
          await context.store.dispatch("user/setUser", res.data)
          EventBus.$emit('user_logged_in')
        }
      } catch (e) {
        //* this prevents client success asking us to create a user on staging. If already a user this doesn't do anything
        // console.log("🚀 ~ file: auth.js ~ line 157 ~ returnnewPromise ~ e", e)
        // let user = {
        //   id: data.attributes.sub,
        //   cognito_id: data.attributes.sub,
        //   email: data.attributes.email,
        //   phone: data.attributes.phone_number,
        //   is_active: true,
        // }
        // let { data: res } = await UserApi.create(user)
        console.error(e)
        firebase.auth().signOut()
        await context.store.dispatch("user/setLoggedIn", false)
        await context.store.dispatch("user/setAccountSwitcherAccounts", null)
        window.Intercom('shutdown')
        window.Intercom("boot", {
          app_id: context.$config.stage === "production" ? "b4bdwx43" : "diipufb9"
        })
        if (context.route.path !== "/login" && context.route.path !== "/login/forgot-password" && context.route.path !== "/chrome" && context.route.path !== "/track") {
          context.redirect("/login")
        }
        EventBus.$emit('user_logged_out')
        setTimeout(() => {
          EventBus.$emit('user_not_found')
        }, 400)
      }

    }
    else if (!hasBeenInitialized && user) {
      let idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
      axios.defaults.headers.common['Authorization'] = idToken
      context.store.dispatch("user/setCurrentAuthToken", idToken)
      EventBus.$emit('user_logged_in')
      initServices(user, context)
      context.store.dispatch("user/setHasBeenInitialized", true)
    }

    const accountId = context.store.getters["user/getSelectedAccountId"]
    const gotUser = context.store.getters["user/getUser"]
    let numAccounts = gotUser.accounts.length
    let isOnboarding = false
    if (numAccounts === 1) {
      if (gotUser.accounts[0].is_onboarding == true) {
        isOnboarding = true
      }
    }
    if (accountId) context.store.dispatch("navigation/buildSidebarMenu", {})
    const selectedAccountId = context.store.selected_account_id
    if ((context.route.path === "/login" || context.route.path === "/login/forgot-password") && selectedAccountId) {
      if (isOnboarding == false) {
        context.redirect(`/dashboard/${ selectedAccountId }`)
      }
      else {
        context.redirect(`/storage-manager/${ selectedAccountId }/locations`)
      }
    }
    else if (context.route.path === "/login" || context.route.path === "/login/forgot-password") {
      context.redirect(`/accounts`)
    }
    else {
      const selectedAccountId = context.store.selected_account_id
      if ((context.route.path === "/login" || context.route.path === "/login/forgot-password") && selectedAccountId) {
        if (isOnboarding == false) {
          context.redirect(`/dashboard/${ selectedAccountId }`)
        }
        else {
          context.redirect(`/storage-manager/${ selectedAccountId }/locations`)
        }
      }
      else if (context.route.path === "/login" || context.route.path === "/login/forgot-password") {
        context.redirect(`/accounts`)
      }
    }
    resolve(true)
  })
}

function initServices(user, context) {
  if (context.$config.stage === "production") {
    window.pug("setUserId", user.email)
    setCookie("pugg_dash", user.id, 4)


    if (window && window.Intercom) {
      window.Intercom('trackEvent', "Login", {
        name: `${ user.name ? user.name : '' }`,
        email: `${ user.email }`,
        accounts: JSON.stringify(_.map(user.accounts, 'name'))
      })
    }
    if (window && window.smartlook) {
      window.smartlook("identify", user.id, {
        name: `${ user.name ? user.name : '' }`,
        email: `${ user.email }`,
        accounts: JSON.stringify(_.map(user.accounts, 'name'))
      })
      window.smartlook("track", "Login", {
        name: `${ user.name ? user.name : '' }`,
        email: `${ user.email }`,
        accounts: JSON.stringify(_.map(user.accounts, 'name'))
      })
    }

  }
  let accounts = _.cloneDeep(user.accounts)
  for (let account of accounts) {
    delete account.privacy_policy
  }

  if (window && window.Intercom) {
    let intercomData = {
      app_id: context.$config.stage === "production" ? "b4bdwx43" : "diipufb9",
      user_id: user.id,
      name: user.name, // Full name
      email: user.email, // Email address
      created_at: Math.round(new Date(user.created_at).getTime() / 1000),
      companies: accounts
    }
    if (user.phone != "+1") {
      intercomData.phone = user.phone
    }
    if (user.user_hash) {
      intercomData.user_hash = user.user_hash
    }
    // console.log("🚀 ~ file: auth.js ~ line 98 ~ returnnewPromise ~ intercomData", intercomData)
    window.Intercom("boot", intercomData)
  }
}

function getCookie(name) {
  var value = "; " + document.cookie
  var parts = value.split("; " + name + "=")
  if (parts.length == 2) return parts.pop().split(";").shift()
}
async function setCookie(cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = "expires=" + d.toUTCString()
  var url = new URL(window.origin)
  var cookieUrl = url.host
  if (url.hostname !== "localhost") cookieUrl = "." + url.host.match(/[^\.]*\.[^.]*$/)[0]
  else cookieUrl = url.hostname
  let cookie = `${ cname }=${ cvalue };${ expires };Domain=${ cookieUrl };path=/;SameSite=None`
  if (url.hostname !== "localhost") {
    cookie += ";Secure"
    let res = await axios.post(generateApiUrl("pugg"), { pugg: cookie }, { withCredentials: true, headers: { accept: "*/*" } })
  } else document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}
