export default {
  mounted() {
  },
  methods: {
    saveActivityToDynamo(event) {
      return new Promise(async (resolve, reject) => {
        try {
          await this.putParams(event, 'lead_manager_events')
          await this.putParams(event, 'lead_manager_user_created_events_backup')
          return resolve(true)
        } catch (e) {
          this.$rollbar.error('Error saving event to dynamo', e, {event: event})
          return reject(e)
        }
      })
    },
    putParams(data, table) {
      return new Promise((resolve, reject) => {
        this.$docClient.put({TableName: table, Item: data}, (err, data) => {
          if (err) {
            console.log(err)
            reject(err)
          } else {
            // console.log(data)
            resolve(data)
          }
        })
      })
    }
  },
}
