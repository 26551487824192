export default function (context) {
  if (process.client) {
    var user = context.store.getters["user/getUser"]
    var selectedAccount = context.store.getters["accounts/getSelectedAccount"]
    var website = context.store.getters["website/getSelectedWebsite"]

    // var fullPath = "locations"
    // var locationActions = ""
    if (selectedAccount && selectedAccount.is_onboarding) {
      // if in onboarding/not live, send to locations page
      let redirect = `/storage-manager/${ selectedAccount.id }/locations`
      let fullPath = "locations"
      let actions

      if (selectedAccount.use_insights_unit_manager === true && !selectedAccount.is_onboarding) {
        fullPath = "view-units"
        redirect = `/units-manager/${ selectedAccount.id }/view-units`
      }

      actions = setActions(user, fullPath)
      if (user.is_super_admin || actions.includes("view")) {
        context.redirect(redirect)
      }
      // } else if (user.is_super_admin) {
      //   // Redirect admin to dashboard home
      //   context.redirect(`/home/${ selectedAccount.id }`)
      // } else if (user.is_website_builder) {
      //   // Redirect website builder as they don't have dashboard access
      //   context.redirect(`/website-manager/${ selectedAccount.id }/editor`)
      // } else {
      //   // Check to see if user has dashboard permissions
      //   let actions = setActions(user, "view-dashboard")
      //   if (actions.includes("view")) {
      //     context.redirect(`/home/${ selectedAccount.id }`)
      //   } else {
      //     // Redirect to next page in sidenav that they have access to 404 if none
      //     let navigation = context.store.getters["navigation/getSidebarMenu"]
      //     if (navigation.sidebarLinks && navigation.sidebarLinks.length >= 1) {
      //       for (let link of navigation.sidebarLinks) {
      //         if (link.path) {
      //           context.redirect(link.path)
      //           break
      //         } else if (link.children && link.children.length >= 1) {
      //           for (let subLink of link.children) {
      //             if (subLink.path) {
      //               context.redirect(subLink.path)
      //               break
      //             }
      //           }
      //         }
      //       }
      //     } else {
      //       context.redirect("404")
      //     }
      //   }
    }
  }
}

function setActions(user, fullPath) {
  let actions = ""
  if (user && user.permissions) {
    for (let perm of user.permissions) {
      if (fullPath.includes(perm.permission.key)) {
        actions = perm.actions
        break
      }
    }
  }
  return actions
}
