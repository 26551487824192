import { mapGetters, mapMutations } from "vuex"
import * as uuid from "uuid/v1"
import { formatToTimeZone } from "date-fns-timezone"
import { EventBus } from "@/utils/event-bus"

export default {
  data() {
    return {
      statusColors: {
        "Scheduled Move In": { icon: "sun", color: "#EB5757" },
        "Attempted to Contact": { icon: "phone-outgoing", color: "#ff7352" },
        "Quote Requested": { icon: "dollar-sign", color: "#EB5757" },
        "Active Lead": { icon: "user-check", color: "#148EFF" },
        "Contacted": { icon: "phone-call", color: "#27AE60" },
        "New Lead": { icon: "user", color: "#27AE60" },
        "Unqualified": { icon: "meh", color: "#595B6E" },
        "Moved Out": { icon: "truck", color: "#252525" },
        "Moved In": { icon: "package", color: "#8647B3" },
        Tenant: { icon: "package", color: "#8647B3" },
      },
      statusArray: ["New Lead", "Active Lead", "Tenant", "Unqualified"],
      unitStatusColors: {
        "Pending": { icon: "clock", color: "#2F80ED" },
        "Quote Requested": { icon: "dollar-sign", color: "#EB5757" },
        "Scheduled Move In": { icon: "sun", color: "#EB5757" },
        "Moved In": { icon: "package", color: "#8647B3" },
        "Did Not Move In": { icon: "slash", color: "#F49F0A" },
        "Moved Out": { icon: "truck", color: "#252525" },
      },
      unitStatusArray: ["Pending", "Quote Requested", "Scheduled Move In", "Moved In", "Did Not Move In", "Moved Out"],
      locationColors: [
        { bg: "rgba(247, 131, 0, 0.1)", main: "#F78300" },
        { bg: "rgba(0, 133, 227, 0.1)", main: "#0085E3" },
        { bg: "rgba(255, 87, 77, 0.1)", main: "#FF574D" },
        { bg: "rgba(53, 169, 111, 0.1)", main: "#35A96F" },
        { bg: "rgba(134, 71, 179, 0.1)", main: "#8647B3" },
        { bg: "rgba(15, 150, 150, 0.1)", main: "#0F9696" },
        { bg: "rgba(50, 76, 221, 0.1)", main: "#324CDD" },
        { bg: "rgba(16, 180, 209, 0.1)", main: "#10B4D1" },
        { bg: "rgba(217, 72, 171, 0.1)", main: "#D948AB" },
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      // contact: "leads/contact",
      selectedAccount: "accounts/getSelectedAccount",

    }),
  },
  methods: {
    async addStatusEvent(status) {
      let eventToSave = {
        event_action: `Status updated: ${ status }`,
        action: "Status updated",
        type: "status_updated",
        app_id: this.selectedAccount.client_code,
        title: `<span class="font-weight-bold" style="color: ${ this.statusColors[status].color }">${ this.user.name }</span> changed the status to <span class="font-weight-bold" style="color: ${ this.statusColors[status].color }">${ status }</span>`,
        category: "Insights Activity",
        inferred_user_id: this.contact.email.toLowerCase(),
        session_id: uuid(),
      }
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      eventToSave.date_tz = formatToTimeZone(new Date(), "YYYY-MM-DD", { timeZone })
      eventToSave.geo_timezone = timeZone
      eventToSave.num = 1
      eventToSave.derived_tstamp = formatToTimeZone(new Date(), "MM/DD/YYYY h:mm:ss.SSS A", { timeZone })
      eventToSave["sort_key"] = `${ eventToSave["inferred_user_id"].toLowerCase() }#${ eventToSave["date_tz"] }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      eventToSave["sort_key_event"] = `${ eventToSave["inferred_user_id"].toLowerCase() }#${ eventToSave.type }#${ eventToSave["date_tz"] }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      eventToSave["sort_key_event_table"] = `${ eventToSave.type }#${ eventToSave.category ? eventToSave.category.replace(/\s+/g, '-').toLowerCase() : eventToSave.category.replace(/\s+/g, '-').toLowerCase() }#${ eventToSave["date_tz"] }#${ eventToSave["inferred_user_id"].toLowerCase() }#${ eventToSave["date_tz"] }#${ eventToSave["inferred_user_id"].toLowerCase() }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      try {
        await this.saveStatusToDynamo(eventToSave)
        EventBus.$emit("add_event_to_activity", eventToSave)
      } catch (e) {
        console.log("🚀 ~ file: PopupCard.vue ~ line 190 ~ saveNote ~ e", e)
        this.$rollbar.error("Error adding dynamo event", e, { event: eventToSave })
      }
    },
    async addStatusEventWithContact(status, contact) {
      let eventToSave = {
        event_action: `Status updated: ${ status }`,
        action: "Status updated",
        type: "status_updated",
        app_id: this.selectedAccount.client_code,
        title: `<span class="font-weight-bold" style="color: ${ this.statusColors[status].color }">${ this.user.name }</span> changed the status to <span class="font-weight-bold" style="color: ${ this.statusColors[status].color }">${ status }</span>`,
        category: "Insights Activity",
        inferred_user_id: contact.email.toLowerCase(),
        session_id: uuid(),
      }
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      eventToSave.date_tz = formatToTimeZone(new Date(), "YYYY-MM-DD", { timeZone })
      eventToSave.geo_timezone = timeZone
      eventToSave.num = 1
      eventToSave.derived_tstamp = formatToTimeZone(new Date(), "MM/DD/YYYY h:mm:ss.SSS A", { timeZone })
      eventToSave["sort_key"] = `${ eventToSave["inferred_user_id"].toLowerCase() }#${ eventToSave["date_tz"] }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      eventToSave["sort_key_event"] = `${ eventToSave["inferred_user_id"].toLowerCase() }#${ eventToSave.type }#${ eventToSave["date_tz"] }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      eventToSave["sort_key_event_table"] = `${ eventToSave.type }#${ eventToSave.category ? eventToSave.category.replace(/\s+/g, '-').toLowerCase() : eventToSave.category.replace(/\s+/g, '-').toLowerCase() }#${ eventToSave["date_tz"] }#${ eventToSave["inferred_user_id"].toLowerCase() }#${ eventToSave["date_tz"] }#${ eventToSave["inferred_user_id"].toLowerCase() }#${ new Date().getTime() }#${ eventToSave["session_id"] }`
      try {
        await this.saveStatusToDynamo(eventToSave)
        EventBus.$emit("add_event_to_activity", eventToSave)
      } catch (e) {
        console.log("🚀 ~ file: PopupCard.vue ~ line 190 ~ saveNote ~ e", e)
        this.$rollbar.error("Error adding dynamo event", e, { event: eventToSave })
      }
    },
    saveStatusToDynamo(event) {
      return new Promise(async (resolve, reject) => {
        try {
          await this.putDynamoParams(event, 'lead_manager_events')
          await this.putDynamoParams(event, 'lead_manager_user_created_events_backup')
          return resolve(true)
        } catch (e) {
          this.$rollbar.error('Error saving event to dynamo', e, { event: event })
          return reject(e)
        }
      })
    },
    putDynamoParams(data, table) {
      return new Promise((resolve, reject) => {
        this.$docClient.put({ TableName: table, Item: data }, (err, data) => {
          if (err) {
            console.log(err)
            reject(err)
          } else {
            // console.log(data)
            resolve(data)
          }
        })
      })
    }
  },
}
