import { createSandbox } from 'vue-kindergarten'
import RouteGoverness from '~/kindergarten/governesses/RouteGoverness'
import baseRouteParameter from '@/kindergarten/perimeters/baseRoute'

import child from '~/kindergarten/child'

export default (context) => {

  const { route, error, redirect, store, isServer } = context

  route.matched.some((routeRecord) => {
    const options = routeRecord.components.default.options
    const perimeter = options.routePerimeter
    const Governess = options.routeGoverness || RouteGoverness
    const action = options.routePerimeterAction || 'route'
    // console.log(route)
    if (perimeter) {
      const sandbox = createSandbox(child(store), {
        governess: new Governess(context),

        perimeters: [perimeter]
      })

      return sandbox.guard(action, context)
    } else if (
      route.path != '/404' &&
      route.path != '/accounts' &&
      route.path != '/login' &&
      route.path != '/track' &&
      route.path != '/chrome' &&
      route.path != '/login/confirm-sign-up' &&
      route.path != '/login/forgot-password' &&
      route.path != '/my-account/security' &&
      route.path != '/'
    ) {
      const sandbox = createSandbox(child(store), {
        governess: new Governess(context),

        perimeters: [baseRouteParameter]
      })

      return sandbox.guard(action, context)
    }
  })
}
