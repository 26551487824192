// import { AmplifyEventBus } from "aws-amplify-vue"

export default function (context) {
  if (process.client) {
    // AmplifyEventBus.$on("authState", (info) => {
    //   // console.log(`Here is the auth event that was just emitted by an Amplify component: ${info}`)
    //   if (info === "signedIn") {
    //     const selectedAccount = context.store.selected_account_id
    //     const redirectUrl = selectedAccount ? `/dashboard/${selectedAccount}` : "/accounts"
    //     context.redirect(redirectUrl)
    //   }
    //   if (info === "signedOut") {
    //     context.redirect("/login")
    //   }
    // })
  }
}
